import LandingContainer from '../features/Auth/components/LandingContainer'
import { NextPage } from 'next'
import LoginForm from '../features/Auth/components/LoginForm'
import PageHead from '../components/Admin/AdminHeader/PageHead'


const Login: NextPage = () => {
    return (
        <LandingContainer>
            <PageHead title="Login" />
            <LoginForm />
        </LandingContainer>
    )
}

export default Login
